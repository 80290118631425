<template>
    <div
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Image</h5>
            </div>
            <div class="modal-body text-center">
                <img :src="modalData.link" class="img-thumbnail">
                <span>{{ modalData.title }}</span>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-clean btn-bold btn-upper btn-font-md" @click="clearData()" >Close</button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modalName: { type: String },
    modalData: { type: Object }
  },
  methods: {
    clearData: function() {
      $("#" + this.modalName).modal("hide");
      this.$emit("update:showModal", false);
    }
  },
  mounted() {
    $("#" + this.modalName).modal();
  }
};
</script>

<style>
</style>
